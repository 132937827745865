import "./App.scss";

import { useRoutes } from "react-router";

import routes from "./routes";

export default () => {
  const routing = useRoutes(routes);

  return <>{routing}</>;
};

import "./index.scss";

import { StrictMode } from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

if (process.env.REACT_APP_GA_MEASURMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASURMENT_ID);
}

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById("root")
);

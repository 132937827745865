import NProgress from "nprogress";
import { useEffect } from "react";

export default () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return <div className={"router-loading"}></div>;
};

import { lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";

import RouterLoadingScreen from "./components/RouterLoading";
import MainLayout from "./layouts/MainLayout";

const Loadable = (Component: LazyExoticComponent<any>) => (props: any) =>
  (
    <Suspense fallback={<RouterLoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const HomePage = Loadable(lazy(() => import("./pages/HomePage")));
const NotFoundPage = Loadable(lazy(() => import("./pages/NotFoundPage")));

export default [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "/", element: <HomePage /> },
      { path: "404", element: <NotFoundPage /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];
